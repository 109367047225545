<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row">
          <h1>{{plural}}</h1>
          <v-btn
              class="mx-2"
              fab
              small
              dark
              color="info"
              @click="createA"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="getGlobalValue('productSerialShowOnInventoryTransfer')==='true'">
        <v-col>
          <v-text-field class="mb-2" hide-details style="width: 500px;" @keydown.enter="searchSerial" dense outlined :label="`Search ${serialNaming.plural}`" v-model="serialSearch"></v-text-field>
          <v-btn color=info small @click="searchSerial" :disabled="!serialSearch || !serialSearch.trim()">Search</v-btn>
          <span v-if="serialSearchResults && serialSearchResults.length > 0" class="d-flex flex-column">
            <b>Found {{ serialSearchResults.reduce((a,b) => a+b.docs.length, 0) }} {{ serialSearchResults.reduce((a,b) => a+b.docs.length, 0) > 1 ? "documents" : "document" }} from {{ serialSearchResults.length }} {{ serialSearchResults.length > 1 ? "products" : "product" }} containing the term "{{ serialSearch }}"</b>
            <span v-for="(result, index) in serialSearchResults" :key="index">
              <hr class="mt-2">
              <b>Product #{{result.Product.id}} | {{result.Product.name}}</b>
              <span class="d-flex flex-row flex-wrap">
                <v-btn v-for="(doc, d) of result.docs" :key="d" class="mr-2 mt-1" small @click="$router.push(`/inventoryTransfers/view/${doc.itId}`)">Transfer #{{ doc.itId }}</v-btn>
              </span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-data-table
                :headers="headers"
                :items="inventoryTransfersReports"
                :options.sync="options"
                :server-items-length="pagination.dataCount"
                :loading="pagination.loading"
                :items-per-page="15"
                class="elevation-1"
                :footer-props="{
                  itemsPerPageOptions: pagination.pageSizeOptions
                }"
                @click:row="rowClick"
            >
              <template v-slot:item.status="{ item }">
                <v-chip :color="utils.getITStatusColor(item.status)">{{utils.parseITStatus(item.status)}}</v-chip>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>{{utils.formatDate(item.createdAt, 'short')}}</span>
              </template>
              <template v-slot:item.sealedAt="{ item }">
                <span>{{utils.formatDate(item.sealedAt, 'short')}}</span>
              </template>
              <template v-slot:item.createdBy="{ item }">
                <span>{{lookupUsername(item.createdBy)}}</span>
              </template>
              <template v-slot:item.sealedBy="{ item }">
                <span>{{lookupUsername(item.sealedBy)}}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="createDialog.isOpen" max-width="490">
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="text-h5 text-center">
          Create New {{singular}}
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" text @click="cancelCreate()">
            Cancel
          </v-btn>
          <v-btn color="success" :loading="confirmLoading" @click="createB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import { mapGetters, mapMutations } from "vuex"
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snack: false,
        snackColor: '',
        snackText: '',
        tableTitle: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: '#', align: 'start', sortable: true, value: 'id' },
          { text: 'Status', align: 'start', sortable: true, value: 'status' },
          { text: '# Items', align: 'start', sortable: true, value: 'itemCount' },
          { text: 'Date Created', align: 'start', sortable: true, value: 'createdAt' },
          { text: 'Created By', align: 'start', sortable: true, value: 'createdBy' },
          { text: 'Date Sealed', align: 'start', sortable: true, value: 'sealedAt' },
          { text: 'Received By', align: 'start', sortable: true, value: 'sealedBy' },
        ],
        editMode: false,
        inventoryTransfersReports: [],

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 15
        },
        updatingRoute: false,
        init: false,

        singular: "Inventory Transfer",
        singularLower: "inventorytransfer",
        plural: "Inventory Transfers",
        pluralLower: "inventorytransfers",

        serialSearch: '',
        serialSearchResults: [],

        createDialog: {
          isOpen: false,
          confirmed: false
        },

        confirmLoading: false
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "createdAt"]
        this.options.sortDesc = [oldQuery.order || false]
        this.options.search = oldQuery.search || ""
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
        try {
          this.init = true;

          this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

          await this.getAllData();
          this.init = false;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getId', 'getEndpoint', 'lookupUsername', 'getGlobalValue', 'serialNaming'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "createdAt"]
          this.options.sortDesc = [oldQuery.order || false]
          this.options.search = oldQuery.search || ""
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      ...mapMutations(['deleteWorkingId']),
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        return oldQuery;
      },
      rowClick(row){
        this.$router.push({ path: `/inventoryTransfers/view/${row.id}`})
      },
      async getAllData(){
        try{
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.inventoryTransfersReports = res.data.data

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/inventoryTransfers`) await this.$router.replace({path: `/inventoryTransfers`, query: newQuery});
            }
            else{
              if(this.$route.path===`/inventoryTransfers`) await this.$router.push({path: `/inventoryTransfers`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      createA(){
        this.createDialog.isOpen = true
      },
      async createB(){
        try {
          this.confirmLoading = true;
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/inventoryTransfers/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
         this.createDialog.isOpen = false
        } finally {
          this.confirmLoading = false;
        }
      },
      cancelCreate(){
        this.createDialog.isOpen = false
      },
      async searchSerial(){
        try{
          if(!this.serialSearch && !this.serialSearch.trim()) return;

          this.loader = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/searchSerial`, {query: this.serialSearch})
          if(res.data.error) throw res.data.error
          this.serialSearchResults = res.data.data
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      clearSerialSearchResults(){
        this.serialSearchResults = []
        this.serialSearch = ''
      },
    }
  }
</script>
